import type {NextPage} from 'next';

import PAGE from 'constants/page';

import LoginPage from 'pages/login';

import useCurrentUserStatus from 'hooks/user/useCurrentUserStatus';

import Page from 'components/App/Page';
import Redirect from 'components/App/Redirect';
import LoadingCard from 'components/Misc/LoadingCard/LoadingCard';

const Home = () => {
  const status = useCurrentUserStatus();

  if (status === 'logged') {
    return <Redirect to={PAGE.APPS.HOME} replace />;
  }

  if (status === 'not-logged') {
    return <LoginPage />;
  }

  return (
    <div className="flex flex-col p-5">
      <LoadingCard />
    </div>
  );
};
const HomePage: NextPage = () => {
  return (
    <Page noAuth title="NoRamp" className="base-size-max gap-4">
      <Home />
    </Page>
  );
};

export default HomePage;
