import type {NextPage} from 'next';

import Auth from 'components/App/Auth';
import Page from 'components/App/Page';
import MotionBackground from 'components/motion/motion-background';

const Login: NextPage = () => {
  return (
    <Page noAuth title="pages.login.title" className="overflow-hidden">
      <div className="m-auto mx-auto">
        <div className="relative flex items-center justify-center">
          <MotionBackground />
          <div className="relative z-10 py-4 shadow-md noramp-bg rounded-2xl">
            <Auth title="NoRamp Login" />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Login;
