import Loading from 'components/Common/Loading';

import Card, {type CardProps} from '../Card/Card';

type LoadingCardProps = CardProps;

const LoadingCard = (props: LoadingCardProps) => {
  return (
    <Card {...props} className="justify-center items-center max-h-50">
      <Loading />
    </Card>
  );
};

export default LoadingCard;
