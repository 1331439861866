'use client';
import {motion} from 'framer-motion';

const container = {
  hidden: {opacity: 0, scale: 5},
  visible: {
    opacity: 1,
    scale: 1,
    transform: 'translateY(50%)',
  },
};
export const MotionBackground = () => {
  return (
    <motion.div
      className="absolute transform sm:rounded-full rounded-t-full h-[700px] w-[700px] bg-[#2B53E1] transition-all duration-300 sm:bottom-[50%] bottom-[20%] sm:translate-y-[50%] translate-y-[80%]"
      variants={container}
      initial="hidden"
      animate="visible"
    />
  );
};

export default MotionBackground;
