import {useEffect, useMemo, useState} from 'react';

import type {NextPage} from 'next';
import {useRouter} from 'next/router';

import NotFound from 'pages/errors/not-found';
import LoginPage from 'pages/login';

import useCurrentUserStatus from 'hooks/user/useCurrentUserStatus';

import HomePage from './home';

const Root: NextPage = () => {
  const {asPath} = useRouter();
  const [isNotFound, setIsNotFound] = useState(false);

  const currentPath = useMemo(() => asPath.split('?')[0], [asPath]);

  const isHome = currentPath === '/';
  const status = useCurrentUserStatus();

  useEffect(() => {
    setIsNotFound(!isHome);
  }, [isHome]);

  if (status === 'not-logged') {
    return <LoginPage />;
  }
  if (isHome) {
    return <HomePage />;
  }

  if (isNotFound) {
    return <NotFound />;
  }

  return null;
};

export default Root;
